$font-family-sans-serif: "Inter", sans-serif;

$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

$grey-1: #1c1c1c;
$grey-2: #444444;
$grey-3: #646464;
$grey-4: #919191;
$grey-5: #aaaaaa;
$grey-6: #bebebe;
$grey-7: #cccccc;
$grey-8: #d7d7d7;
$grey-9: #e6e6e6;
$grey-10: #eeeeee;
$grey-11: #f5f5f5;
$grey-12: #fafafa;

$primary: #ea580c;
$secondary: $grey-11 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $grey-12 !default;
$dark: $grey-1 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "grey-1": $grey-1,
  "grey-2": $grey-2,
  "grey-3": $grey-3,
  "grey-4": $grey-4,
  "grey-5": $grey-5,
  "grey-6": $grey-6,
  "grey-7": $grey-7,
  "grey-8": $grey-8,
  "grey-9": $grey-9,
  "grey-10": $grey-10,
  "grey-11": $grey-11,
  "grey-12": $grey-12,
) !default;

$font-size-base: 1rem !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
$xs-font-size: $font-size-base * 0.75;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  xs: $xs-font-size,
);

@import "~bootstrap/scss/bootstrap.scss";
