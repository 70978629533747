@import "./variables.scss";

.mb-weather-card {
  background-color: white;
  border-radius: 1rem;
  border: 1px solid $grey-10;
  overflow: hidden;
  .mb-location-description {
    font-size: 1.25rem;
    @media (max-width: 980px) {
      font-size: 1rem;
    }
  }
  .mb-location-image {
    width: 100%;
    height: 300px;
    display: block;
    object-fit: cover;
    object-position: center center;
    border-radius: 0.75rem;
  }
  .mb-weather-info-container {
    display: flex;
    gap: 3rem;
  }
}
