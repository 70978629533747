@import "./variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: $primary;
}
