@import "./variables.scss";
.mb-icon-group {
  background-color: white;
  height: 80px;
  width: 80px;
  border-radius: 100px;
  border: 2px solid rgb(234 88 12 / 10%);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 70px;
  }
}
.mb-wind {
  img {
    width: 1rem;
    margin-right: 0.5rem;
  }
  span {
    font-size: 0.875rem;
  }
}
